import { FlightServiceClient } from '@api/airplane/web_partner/flight_grpc_web_pb';
import {
  BaggageSelection,
  BookingFlightDomesticRes,
  BookingFlightReq,
  BookingFlightRes,
  CalcMakeupPriceReq,
  CalcMakeupPriceRes,
  CalculationFlightFeeDomesticReq,
  CalculationFlightFeeDomesticRes,
  CalculationFlightFeeReq,
  CalculationFlightFeeRes,
  CancelBookingFlightReq,
  ExportBookingFlightReq,
  ExportBookingFlightRes,
  FareCheckDomesticReq,
  FareCheckReq,
  FareCheckRes,
  FlightReq,
  GetAirportsReq,
  GetAirportsRes,
  GetBaggageOptionsReq,
  GetBaggageOptionsRes,
  GetSeatMapReq,
  GetSeatMapRes,
  IssueTicketReq,
  IssueTicketRes,
  ListBookingFlightReq,
  ListBookingFlightRes,
  PassengerQuantity,
  RetrieveBookingFlightByTokenReq,
  RetrieveBookingFlightReq,
  RetrieveBookingFlightRes,
  SearchAirlinesReq,
  SearchAirlinesRes,
  SearchAirportsFilter,
  SearchAirportsReq,
  SearchAirportsRes,
  SearchFlightDomesticFilterReq,
  SearchFlightDomesticReq,
  SearchFlightDomesticRes,
  SearchFlightFilterReq,
  SearchFlightHistoryReq,
  SearchFlightHistoryRes,
  SearchFlightReq,
  SearchFlightRes,
  SeatSelection,
  SendMailIssueSuccessReq,
  SortItem,
  SortItemType,
  UpdateBookingFlightReq,
  UpdateBookingManualServiceFeeReq,
  CreateTransferBookingRequestReq,
  ListTransferBookingRequestReq,
  ListTransferBookingRequestRes,
  GetTransferBookingRequestDetailReq,
  GetTransferBookingRequestDetailRes,
  HandleTransferRequestActionReq,
  ReBookingFlightDomesticReq,
  CancelTransferBookingRequestReq,
  ListCARes,
  ManualPaymentReceipt,
  ManualServiceFee,
  FeeConfig,
  PaymentMethod,
  CAInfo,
  DefaultServiceFeeRes,
  BizziBookingFlightRes,
  BizziIssueTicketReq,
  BizziIssueTicketRes,
  BizziRetrieveBookingFlightReq,
} from '@api/airplane/web_partner/flight_pb';

import { BasicRes, Empty, OrderByItem, PaginationReq } from '@api/base/base_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { convertLocalToUTCDate } from '@core/utils';

import { InvoicingInformation } from '@api/airplane/flight_pb';
import { BaseApi } from '../base-api';
import booking from '@web-booker/stores/reducers/booking';

class FlightApi extends BaseApi<FlightServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);

    this.serviceClient = new FlightServiceClient(this.url, null, this.optsDev);
  }

  searchAirports = ({
    filter,
    orderByList,
    pagination,
  }: Partial<SearchAirportsReq.AsObject>): Promise<SearchAirportsRes.AsObject> => {
    const req = new SearchAirportsReq();
    const filterObj = new SearchAirportsFilter();

    const paginationObj = new PaginationReq();

    const orderByListObj = orderByList?.map((orderByList) => {
      const order = new OrderByItem();
      order.setDesc(orderByList.desc);
      order.setField(orderByList.field);
      return order;
    });

    if (filter) {
      filterObj.setSearchText(filter.searchText);
    }
    if (pagination) {
      paginationObj.setPageNumber(pagination.pageNumber);
      paginationObj.setPageLimit(pagination.pageLimit);
    }

    req.setFilter(filterObj);
    if (orderByListObj) {
      req.setOrderByList(orderByListObj);
    }

    req.setPagination(paginationObj);

    // return this.createDummyResponse<SearchAirportsRes.AsObject>({
    //   airportsList: airportList,
    // });

    return this.grpc<
      SearchAirportsReq,
      SearchAirportsRes,
      SearchAirportsRes.AsObject
    >(this.serviceClient?.searchAirports, req, {});
  };

  searchFlight = ({
    sortsList = [],
    filter,
    pagination = {
      pageLimit: 100,
      pageNumber: 1,
    },
  }: SearchFlightReq.AsObject): Promise<SearchFlightRes.AsObject> => {
    const req = new SearchFlightReq();
    const filterObj = new SearchFlightFilterReq();
    const paginationObj = new PaginationReq();
    const passengerQuantityObj = new PassengerQuantity();

    if (filter) {
      filterObj.setFlightsList(
        filter.flightsList.map((f) => {
          const flightReqObj = new FlightReq();
          flightReqObj.setStartPoint(f.startPoint);
          flightReqObj.setEndPoint(f.endPoint);

          const currentDepartDate = new Date(f.departDate);

          const UTCDate = new Date(
            f.departDate + new Date().getTimezoneOffset() * 60 * 1000
          );
          if (currentDepartDate.getDate() !== UTCDate.getDate()) {
            currentDepartDate.setHours(0, 0, 0, 0);
            flightReqObj.setDepartDate(
              currentDepartDate.getTime() -
                new Date().getTimezoneOffset() * 60 * 1000
            );
          } else {
            flightReqObj.setDepartDate(
              // new Date(f.departDate).setHours(0, 0, 0, 0) + new Date().getTimezoneOffset() * 60 * 1000
              f.departDate
            );
          }

          return flightReqObj;
        })
      );

      if (filter.passengerQuantity) {
        passengerQuantityObj.setAdt(filter.passengerQuantity.adt);
        passengerQuantityObj.setChd(filter.passengerQuantity.chd);
        passengerQuantityObj.setInf(filter.passengerQuantity.inf);
      }

      filter.passengerQuantity &&
        filterObj.setPassengerQuantity(passengerQuantityObj);
    }

    filterObj.setCurrency('VND');

    req.setFilter(filterObj);

    paginationObj.setPageNumber(pagination.pageNumber);
    paginationObj.setPageLimit(pagination.pageLimit);
    req.setPagination(paginationObj);
    req.setSortsList(
      sortsList.map((s) => {
        const sortItemObj = new SortItem();
        sortItemObj.setDesc(s.desc || false);
        sortItemObj.setType(s.type || SortItemType.SORT_ITEM_TYPE_FASTEST);
        return sortItemObj;
      })
    );

    return this.grpc<
      SearchFlightReq,
      SearchFlightRes,
      SearchFlightRes.AsObject
    >(this.serviceClient?.searchFlight, req, {});
  };

  searchFlightDomestic = ({
    sortsList = [],
    filter,
    pagination = {
      pageLimit: 100,
      pageNumber: 1,
    },
  }: SearchFlightDomesticReq.AsObject): Promise<SearchFlightDomesticRes.AsObject> => {
    const req = new SearchFlightDomesticReq();
    const paginationObj = new PaginationReq();
    const filterObj = new SearchFlightDomesticFilterReq();

    if (filter) {
      const flightReqObj = new FlightReq();
      if (filter.flight) {
        flightReqObj.setStartPoint(filter.flight.startPoint);
        flightReqObj.setEndPoint(filter.flight.endPoint);
        const currentDepartDate = new Date(filter.flight.departDate);

        const UTCDate = new Date(
          filter.flight.departDate + new Date().getTimezoneOffset() * 60 * 1000
        );
        if (currentDepartDate.getDate() !== UTCDate.getDate()) {
          currentDepartDate.setHours(0, 0, 0, 0);
          flightReqObj.setDepartDate(
            currentDepartDate.getTime() -
              new Date().getTimezoneOffset() * 60 * 1000
          );
        } else {
          flightReqObj.setDepartDate(
            // new Date(filter.flight.departDate).setHours(0, 0, 0, 0) + new Date().getTimezoneOffset() * 60 * 1000
            filter.flight.departDate
          );
        }
      }
      const passengerQuantityObj = new PassengerQuantity();
      if (filter.passengerQuantity) {
        passengerQuantityObj.setAdt(filter.passengerQuantity.adt);
        passengerQuantityObj.setChd(filter.passengerQuantity.chd);
        passengerQuantityObj.setInf(filter.passengerQuantity.inf);
      }

      filterObj.setFlight(flightReqObj);
      filterObj.setPassengerQuantity(passengerQuantityObj);
      filterObj.setCurrency('VND');
    }

    req.setFilter(filterObj);

    paginationObj.setPageNumber(pagination.pageNumber);
    paginationObj.setPageLimit(pagination.pageLimit);
    req.setPagination(paginationObj);
    req.setSortsList(
      sortsList.map((s) => {
        const sortItemObj = new SortItem();
        sortItemObj.setDesc(s.desc || false);
        sortItemObj.setType(s.type || SortItemType.SORT_ITEM_TYPE_FASTEST);
        return sortItemObj;
      })
    );

    return this.grpc<
      SearchFlightDomesticReq,
      SearchFlightDomesticRes,
      SearchFlightDomesticRes.AsObject
    >(this.serviceClient?.searchFlightDomestic, req, {});
  };

  getAirports = ({
    codesList,
  }: GetAirportsReq.AsObject): Promise<GetAirportsRes.AsObject> => {
    const req = new GetAirportsReq();
    req.setCodesList(codesList);

    return this.grpc<GetAirportsReq, GetAirportsRes, GetAirportsRes.AsObject>(
      this.serviceClient.getAirports,
      req,
      {}
    );
  };

  fareCheck = ({
    flightId,
    key,
  }: FareCheckReq.AsObject): Promise<FareCheckRes.AsObject> => {
    const req = new FareCheckReq();
    req.setFlightId(flightId);
    req.setKey(key);

    return this.grpc<FareCheckReq, FareCheckRes, FareCheckRes.AsObject>(
      this.serviceClient.fareCheck,
      req,
      {}
    );
  };
  fareCheckDomestic = ({
    flightsList,
  }: FareCheckDomesticReq.AsObject): Promise<FareCheckRes.AsObject> => {
    const req = new FareCheckDomesticReq();
    req.setFlightsList(
      flightsList.map((f) => {
        const flightObj = new FareCheckDomesticReq.CheckFlightInfo();
        flightObj.setFlightId(f.flightId);
        flightObj.setKey(f.key);
        return flightObj;
      })
    );

    return this.grpc<FareCheckDomesticReq, FareCheckRes, FareCheckRes.AsObject>(
      this.serviceClient.fareCheckDomestic,
      req,
      {}
    );
  };

  bookingFlight = ({
    currency,
    flightId,
    isPublic,
    key,
    note,
    sessionId,
    passengersList,
    contactInfo,
    caInfo,
    manualServiceFee,
    confirmPrice,
    invoicingInformation,
    timezone,
    makeupPrice,
    domesticFlightPairsList,
    isDraftForOverride,
  }: BookingFlightReq.AsObject): Promise<BookingFlightRes.AsObject> => {
    const req = new BookingFlightReq();

    req.setCurrency(currency);
    req.setFlightId(flightId);
    req.setIsPublic(isPublic);
    req.setKey(key);
    req.setNote(note);
    req.setSessionId(sessionId);
    req.setConfirmPrice(confirmPrice);
    req.setTimezone(timezone);
    req.setMakeupPrice(makeupPrice);
    req.setDomesticFlightPairsList(
      domesticFlightPairsList.map((domesticFlight) => {
        const domesticFlightObj = new BookingFlightReq.FlightPair();

        domesticFlightObj.setFlightId(domesticFlight.flightId);
        domesticFlightObj.setKey(domesticFlight.key);

        return domesticFlightObj;
      })
    );

    if (invoicingInformation) {
      const invoicingInfo = new InvoicingInformation();
      if (invoicingInformation.companyInfo) {
        const invoicingCompany = new InvoicingInformation.Company();
        invoicingCompany.setAddress(invoicingInformation.companyInfo?.address);
        invoicingCompany.setName(invoicingInformation.companyInfo?.name);
        invoicingCompany.setTaxCode(invoicingInformation.companyInfo?.taxCode);
        invoicingCompany.setEmail(invoicingInformation.companyInfo?.email);
        invoicingInfo.setCompanyInfo(invoicingCompany);
      }
      if (invoicingInformation.receiverInfo) {
        const invoicingReceiver = new InvoicingInformation.Receiver();
        invoicingReceiver.setAddress(
          invoicingInformation.receiverInfo?.address
        );
        invoicingReceiver.setName(invoicingInformation.receiverInfo?.name);
        invoicingReceiver.setEmail(invoicingInformation.receiverInfo?.email);
        invoicingReceiver.setNote(invoicingInformation.receiverInfo?.note);
        invoicingReceiver.setPhone(invoicingInformation.receiverInfo?.phone);
        invoicingInfo.setReceiverInfo(invoicingReceiver);
      }

      req.setInvoicingInformation(invoicingInfo);
    }
    const passengersListReq = passengersList.map((passenger) => {
      const passengerReq = new BookingFlightReq.Passenger();
      const {
        dob,
        firstName,
        gender,
        lastName,
        type,
        expirationDate,
        nationality,
        passportNumber,
        idIssueCountry,
        seatOptionsList,
        baggageOptionsList,
      } = passenger;

      seatOptionsList.forEach((seatOption) => {
        const seatOptionReq = new SeatSelection();
        seatOptionReq.setItineraryId(seatOption.itineraryId);
        seatOptionReq.setSegmentIndex(seatOption.segmentIndex);
        seatOptionReq.setRowNumber(seatOption.rowNumber);
        seatOptionReq.setSeatCode(seatOption.seatCode);

        passengerReq.addSeatOptions(seatOptionReq);
      });
      baggageOptionsList.forEach((b) => {
        const baggageObj = new BaggageSelection();
        baggageObj.setItineraryId(b.itineraryId);
        baggageObj.setOptionId(b.optionId);

        passengerReq.addBaggageOptions(baggageObj);
      });

      passengerReq.setDob(convertLocalToUTCDate(dob ?? 0).getTime());
      passengerReq.setFirstName(firstName);
      passengerReq.setLastName(lastName);
      passengerReq.setType(type);
      passengerReq.setGender(gender);

      expirationDate && passengerReq.setExpirationDate(expirationDate);
      nationality && passengerReq.setNationality(nationality);
      passportNumber && passengerReq.setPassportNumber(passportNumber);
      idIssueCountry && passengerReq.setIdIssueCountry(idIssueCountry);

      return passengerReq;
    });
    req.setPassengersList(passengersListReq);

    const contactInfoReq = new BookingFlightReq.ContactInfo();
    if (contactInfo) {
      contactInfoReq.setTitle(contactInfo.title);
      contactInfoReq.setFirstName(contactInfo.firstName);
      contactInfoReq.setLastName(contactInfo.lastName);

      contactInfoReq.setPhoneCode(contactInfo.phoneCode);
      contactInfoReq.setPhoneNumber(contactInfo.phoneNumber);
      contactInfoReq.setEmail(contactInfo.email);
      contactInfoReq.setAddress(contactInfo.address);
      contactInfoReq.setNote(contactInfo.note);
    }
    req.setContactInfo(contactInfoReq);
    req.setIsDraftForOverride(isDraftForOverride);
    // if (isPublic) {
    //   console.log('BOOKING', req.toObject());
    //   console.groupEnd();
    // } else {
    //   console.group(`START Booking: ${key}`);

    //   console.log('VALIDATE BOOKING', req.toObject());
    // }
    const caInfoReq = new CAInfo();
    if (caInfo) {
      caInfoReq.setId(caInfo.id);
      caInfoReq.setName(caInfo.name);
    }
    req.setCaInfo(caInfoReq);

    const manualServiceFeeReq = new ManualServiceFee();
    if (manualServiceFee) {
      const feeConfigDomestic = new FeeConfig();
      if (manualServiceFee.domestic) {
        feeConfigDomestic.setEconomy(manualServiceFee.domestic.economy);
        feeConfigDomestic.setBusiness(manualServiceFee.domestic.business);
        manualServiceFeeReq.setDomestic(feeConfigDomestic);
      }
      const feeConfigInternational = new FeeConfig();
      if (manualServiceFee.international) {
        feeConfigInternational.setEconomy(
          manualServiceFee.international.economy
        );
        feeConfigInternational.setBusiness(
          manualServiceFee.international.business
        );
        manualServiceFeeReq.setInternational(feeConfigInternational);
      }
      manualServiceFeeReq.setDifferenceFee(manualServiceFee.differenceFee);
      manualServiceFeeReq.setIsDebtCa(manualServiceFee.isDebtCa);
    }
    req.setManualServiceFee(manualServiceFeeReq);

    const manualPaymentReceiptReq = new ManualPaymentReceipt();
    const paymentMethodReq = new PaymentMethod();
    manualPaymentReceiptReq.setPaymentMethod(paymentMethodReq);
    req.setManualPaymentReceipt(manualPaymentReceiptReq);

    return this.grpc<
      BookingFlightReq,
      BookingFlightRes,
      BookingFlightRes.AsObject
    >(this.serviceClient.bookingFlight, req, {});
  };

  bookingFlightDomestic = ({
    currency,
    flightId,
    isPublic,
    key,
    note,
    sessionId,
    passengersList,
    contactInfo,
    caInfo,
    manualServiceFee,
    confirmPrice,
    invoicingInformation,
    timezone,
    makeupPrice,
    domesticFlightPairsList,
    isDraftForOverride,
  }: BookingFlightReq.AsObject): Promise<BookingFlightDomesticRes.AsObject> => {
    const req = new BookingFlightReq();

    req.setCurrency(currency);
    req.setFlightId(flightId);
    req.setIsPublic(isPublic);
    req.setKey(key);
    req.setNote(note);
    req.setSessionId(sessionId);
    req.setConfirmPrice(confirmPrice);
    req.setTimezone(timezone);
    req.setMakeupPrice(makeupPrice);
    req.setDomesticFlightPairsList(
      domesticFlightPairsList.map((domesticFlight) => {
        const domesticFlightObj = new BookingFlightReq.FlightPair();

        domesticFlightObj.setFlightId(domesticFlight.flightId);
        domesticFlightObj.setKey(domesticFlight.key);

        return domesticFlightObj;
      })
    );

    if (invoicingInformation) {
      const invoicingInfo = new InvoicingInformation();
      if (invoicingInformation.companyInfo) {
        const invoicingCompany = new InvoicingInformation.Company();
        invoicingCompany.setAddress(invoicingInformation.companyInfo?.address);
        invoicingCompany.setName(invoicingInformation.companyInfo?.name);
        invoicingCompany.setTaxCode(invoicingInformation.companyInfo?.taxCode);
        invoicingCompany.setEmail(invoicingInformation.companyInfo?.email);
        invoicingInfo.setCompanyInfo(invoicingCompany);
      }
      if (invoicingInformation.receiverInfo) {
        const invoicingReceiver = new InvoicingInformation.Receiver();
        invoicingReceiver.setAddress(
          invoicingInformation.receiverInfo?.address
        );
        invoicingReceiver.setName(invoicingInformation.receiverInfo?.name);
        invoicingReceiver.setEmail(invoicingInformation.receiverInfo?.email);
        invoicingReceiver.setNote(invoicingInformation.receiverInfo?.note);
        invoicingReceiver.setPhone(invoicingInformation.receiverInfo?.phone);
        invoicingInfo.setReceiverInfo(invoicingReceiver);
      }

      req.setInvoicingInformation(invoicingInfo);
    }
    const passengersListReq = passengersList.map((passenger) => {
      const passengerReq = new BookingFlightReq.Passenger();
      const {
        dob,
        firstName,
        gender,
        lastName,
        type,
        expirationDate,
        nationality,
        passportNumber,
        idIssueCountry,
        seatOptionsList,
        baggageOptionsList,
      } = passenger;

      seatOptionsList.forEach((seatOption) => {
        const seatOptionReq = new SeatSelection();
        seatOptionReq.setItineraryId(seatOption.itineraryId);
        seatOptionReq.setSegmentIndex(seatOption.segmentIndex);
        seatOptionReq.setRowNumber(seatOption.rowNumber);
        seatOptionReq.setSeatCode(seatOption.seatCode);

        passengerReq.addSeatOptions(seatOptionReq);
      });
      baggageOptionsList.forEach((b) => {
        const baggageObj = new BaggageSelection();
        baggageObj.setItineraryId(b.itineraryId);
        baggageObj.setOptionId(b.optionId);

        passengerReq.addBaggageOptions(baggageObj);
      });

      passengerReq.setDob(convertLocalToUTCDate(dob ?? 0).getTime());
      passengerReq.setFirstName(firstName);
      passengerReq.setLastName(lastName);
      passengerReq.setType(type);
      passengerReq.setGender(gender);

      expirationDate && passengerReq.setExpirationDate(expirationDate);
      nationality && passengerReq.setNationality(nationality);
      passportNumber && passengerReq.setPassportNumber(passportNumber);
      idIssueCountry && passengerReq.setIdIssueCountry(idIssueCountry);

      return passengerReq;
    });
    req.setPassengersList(passengersListReq);

    const contactInfoReq = new BookingFlightReq.ContactInfo();
    if (contactInfo) {
      contactInfoReq.setTitle(contactInfo.title);
      contactInfoReq.setFirstName(contactInfo.firstName);
      contactInfoReq.setLastName(contactInfo.lastName);

      contactInfoReq.setPhoneCode(contactInfo.phoneCode);
      contactInfoReq.setPhoneNumber(contactInfo.phoneNumber);
      contactInfoReq.setEmail(contactInfo.email);
      contactInfoReq.setAddress(contactInfo.address);
      contactInfoReq.setNote(contactInfo.note);
    }
    req.setContactInfo(contactInfoReq);
    const caInfoReq = new CAInfo();
    if (caInfo) {
      caInfoReq.setId(caInfo.id);
      caInfoReq.setName(caInfo.name);
    }
    req.setCaInfo(caInfoReq);

    const manualServiceFeeReq = new ManualServiceFee();
    if (manualServiceFee) {
      const feeConfigDomestic = new FeeConfig();
      if (manualServiceFee.domestic) {
        feeConfigDomestic.setEconomy(manualServiceFee.domestic.economy);
        feeConfigDomestic.setBusiness(manualServiceFee.domestic.business);
        manualServiceFeeReq.setDomestic(feeConfigDomestic);
      }
      const feeConfigInternational = new FeeConfig();
      if (manualServiceFee.international) {
        feeConfigInternational.setEconomy(
          manualServiceFee.international.economy
        );
        feeConfigInternational.setBusiness(
          manualServiceFee.international.business
        );
        manualServiceFeeReq.setInternational(feeConfigInternational);
      }
      manualServiceFeeReq.setDifferenceFee(manualServiceFee.differenceFee);
      manualServiceFeeReq.setIsDebtCa(manualServiceFee.isDebtCa);
    }
    req.setManualServiceFee(manualServiceFeeReq);

    const manualPaymentReceiptReq = new ManualPaymentReceipt();
    const paymentMethodReq = new PaymentMethod();
    manualPaymentReceiptReq.setPaymentMethod(paymentMethodReq);
    req.setManualPaymentReceipt(manualPaymentReceiptReq);

    // if (isPublic) {
    //   console.log('BOOKING', req.toObject());
    //   console.groupEnd();
    // } else {
    //   console.group(`START Booking: ${key}`);

    //   console.log('VALIDATE BOOKING', req.toObject());
    // }
    req.setIsDraftForOverride(isDraftForOverride);

    return this.grpc<
      BookingFlightReq,
      BookingFlightDomesticRes,
      BookingFlightDomesticRes.AsObject
    >(this.serviceClient.bookingFlightDomestic, req, {});
  };

  issueTicket = ({
    bookingCode,
  }: IssueTicketReq.AsObject): Promise<IssueTicketRes.AsObject> => {
    const req = new IssueTicketReq();
    req.setBookingCode(bookingCode);

    return this.grpc<IssueTicketReq, IssueTicketRes, IssueTicketRes.AsObject>(
      this.serviceClient.issueTicket,
      req,
      {}
    );
  };

  reBookingFlightDomestic = ({
    masterBookingCode,
    newBookingCode,
  }: ReBookingFlightDomesticReq.AsObject): Promise<BookingFlightDomesticRes.AsObject> => {
    const req = new ReBookingFlightDomesticReq();
    req.setNewBookingCode(newBookingCode);
    req.setMasterBookingCode(masterBookingCode);

    return this.grpc<
      ReBookingFlightDomesticReq,
      BookingFlightDomesticRes,
      BookingFlightDomesticRes.AsObject
    >(this.serviceClient.reBookingFlightDomestic, req, {});
  };

  cancelBookingFlight = ({
    id,
  }: CancelBookingFlightReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new CancelBookingFlightReq();
    req.setId(id);

    return this.grpc<CancelBookingFlightReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.cancelBookingFlight,
      req,
      {}
    );
  };

  retrieveBookingFlight = ({
    bookingCode,
  }: RetrieveBookingFlightReq.AsObject): Promise<RetrieveBookingFlightRes.AsObject> => {
    const req = new RetrieveBookingFlightReq();

    req.setBookingCode(bookingCode);

    return this.grpc<
      RetrieveBookingFlightReq,
      RetrieveBookingFlightRes,
      RetrieveBookingFlightRes.AsObject
    >(this.serviceClient.retrieveBookingFlight, req, {});
  };

  sendMailIssueSuccess = ({
    bookingCode,
  }: SendMailIssueSuccessReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new SendMailIssueSuccessReq();

    req.setBookingCode(bookingCode);

    return this.grpc<SendMailIssueSuccessReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.sendMailIssueSuccess,
      req,
      {}
    );
  };
  updateBookingFlight = ({
    id,
    note,
  }: UpdateBookingFlightReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new UpdateBookingFlightReq();

    req.setId(id);
    req.setNote(note);

    return this.grpc<UpdateBookingFlightReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.updateBookingFlight,
      req,
      {}
    );
  };
  updateBookingManualServiceFee = ({
    bookingCode,
    manualServiceFee,
    manualPaymentReceipt,
  }: UpdateBookingManualServiceFeeReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new UpdateBookingManualServiceFeeReq();

    req.setBookingCode(bookingCode);
    const manualServiceFeeReq = new ManualServiceFee();
    if (manualServiceFee) {
      const feeConfigDomestic = new FeeConfig();
      if (manualServiceFee.domestic) {
        feeConfigDomestic.setEconomy(manualServiceFee.domestic.economy);
        feeConfigDomestic.setBusiness(manualServiceFee.domestic.business);
      }
      manualServiceFeeReq.setDomestic(feeConfigDomestic);
      const feeConfigInternational = new FeeConfig();
      if (manualServiceFee.international) {
        feeConfigInternational.setEconomy(
          manualServiceFee.international.economy
        );
        feeConfigInternational.setBusiness(
          manualServiceFee.international.business
        );
      }
      manualServiceFeeReq.setInternational(feeConfigInternational);
      manualServiceFeeReq.setDifferenceFee(manualServiceFee.differenceFee);
      manualServiceFeeReq.setIsDebtCa(manualServiceFee.isDebtCa);
    }
    req.setManualServiceFee(manualServiceFeeReq);

    const manualPaymentReceiptState = new ManualPaymentReceipt();
    if (manualPaymentReceipt) {
      const paymentMethod = new PaymentMethod();
      paymentMethod.setCode(manualPaymentReceipt?.paymentMethod?.code || '');
      paymentMethod.setName(manualPaymentReceipt?.paymentMethod?.name || '');
      manualPaymentReceiptState.setPaymentMethod(paymentMethod);
      manualPaymentReceiptState.setTransactionCode(
        manualPaymentReceipt.transactionCode
      );
    }
    req.setManualPaymentReceipt(manualPaymentReceiptState);
    return this.grpc<
      UpdateBookingManualServiceFeeReq,
      BasicRes,
      BasicRes.AsObject
    >(this.serviceClient.updateBookingManualServiceFee, req, {});
  };
  retrieveBookingFlightByToken = ({
    bookingCode,
    token,
  }: RetrieveBookingFlightByTokenReq.AsObject): Promise<RetrieveBookingFlightRes.AsObject> => {
    const req = new RetrieveBookingFlightByTokenReq();
    req.setToken(token);
    req.setBookingCode(bookingCode);

    return this.grpc<
      RetrieveBookingFlightByTokenReq,
      RetrieveBookingFlightRes,
      RetrieveBookingFlightRes.AsObject
    >(this.serviceClient.retrieveBookingFlightByToken, req, {});
  };

  calculationFlightFee = ({
    flightId,
    key,
    passengerQuantity,
  }: CalculationFlightFeeReq.AsObject): Promise<CalculationFlightFeeRes.AsObject> => {
    const req = new CalculationFlightFeeReq();
    req.setFlightId(flightId);
    req.setKey(key);
    const passengerQuantityReq = new PassengerQuantity();
    if (passengerQuantity) {
      passengerQuantityReq.setAdt(passengerQuantity.adt);
      passengerQuantityReq.setChd(passengerQuantity.chd);
      passengerQuantityReq.setInf(passengerQuantity.inf);
    }
    req.setPassengerQuantity(passengerQuantityReq);

    return this.grpc<
      CalculationFlightFeeReq,
      CalculationFlightFeeRes,
      CalculationFlightFeeRes.AsObject
    >(this.serviceClient.calculationFlightFee, req, {});
  };

  calculationFlightFeeDomestic = ({
    flightsList,
  }: CalculationFlightFeeDomesticReq.AsObject): Promise<CalculationFlightFeeDomesticRes.AsObject> => {
    const req = new CalculationFlightFeeDomesticReq();

    req.setFlightsList(
      flightsList.map((f) => {
        const flightPairObj = new CalculationFlightFeeDomesticReq.FlightPair();
        flightPairObj.setFlightId(f.flightId);
        flightPairObj.setKey(f.key);

        return flightPairObj;
      })
    );

    return this.grpc<
      CalculationFlightFeeDomesticReq,
      CalculationFlightFeeDomesticRes,
      CalculationFlightFeeDomesticRes.AsObject
    >(this.serviceClient.calculationFlightFeeDomestic, req, {});
  };

  listBookingFlight = ({
    sortsList = [],
    filter,
    pagination = {
      pageLimit: 100,
      pageNumber: 1,
    },
  }: ListBookingFlightReq.AsObject): Promise<ListBookingFlightRes.AsObject> => {
    const req = new ListBookingFlightReq();
    const filterObj = new ListBookingFlightReq.Filter();
    const paginationObj = new PaginationReq();

    if (filter) {
      filter.toDate && filterObj.setToDate(filter.toDate);

      filter.fromDate && filterObj.setFromDate(filter.fromDate);

      filter.passengerName &&
        filterObj.setPassengerName(filter.passengerName?.trim() || '');

      filter.contactPhone &&
        filterObj.setContactPhone(filter.contactPhone?.trim() || '');

      filter.bookingCode &&
        filterObj.setBookingCode(filter.bookingCode?.trim() || '');

      filterObj.setStatusesList(filter.statusesList);
    }

    req.setFilter(filterObj);

    paginationObj.setPageNumber(pagination.pageNumber);
    paginationObj.setPageLimit(pagination.pageLimit);
    req.setPagination(paginationObj);
    req.setSortsList(
      sortsList.map((s) => {
        const sortItemObj = new SortItem();
        sortItemObj.setDesc(s.desc);
        sortItemObj.setType(s.type);
        return sortItemObj;
      })
    );

    return this.grpc<
      ListBookingFlightReq,
      ListBookingFlightRes,
      ListBookingFlightRes.AsObject
    >(this.serviceClient?.listBookingFlight, req, {});
  };
  listCA = (): Promise<ListCARes.AsObject> => {
    const req = new Empty();
    return this.grpc<Empty, ListCARes, ListCARes.AsObject>(
      this.serviceClient?.listCA,
      req,
      {}
    );
  };
  getDefaultServiceFee = (): Promise<DefaultServiceFeeRes.AsObject> => {
    const req = new Empty();
    return this.grpc<
      Empty,
      DefaultServiceFeeRes,
      DefaultServiceFeeRes.AsObject
    >(this.serviceClient?.getDefaultServiceFee, req, {});
  };
  searchFlightHistory = (): Promise<SearchFlightHistoryRes.AsObject> => {
    const req = new SearchFlightHistoryReq();

    return this.grpc<
      SearchFlightHistoryReq,
      SearchFlightHistoryRes,
      SearchFlightHistoryRes.AsObject
    >(this.serviceClient?.searchFlightHistory, req, {});
  };
  searchAirlines = ({
    filter,
    pagination,
  }: SearchAirlinesReq.AsObject): Promise<SearchAirlinesRes.AsObject> => {
    const req = new SearchAirlinesReq();
    const filterReq = new SearchAirlinesReq.Filter();
    if (pagination) {
      const paginationReq = new PaginationReq();
      paginationReq.setPageLimit(pagination.pageLimit);
      paginationReq.setPageNumber(pagination.pageNumber);
      req.setPagination(paginationReq);
    } else {
      const paginationReq = new PaginationReq();
      paginationReq.setPageLimit(9999);
      paginationReq.setPageNumber(1);
      req.setPagination(paginationReq);
    }

    filterReq.setText(filter?.text || '');
    req.setFilter(filterReq);

    return this.grpc<
      SearchAirlinesReq,
      SearchAirlinesRes,
      SearchAirlinesRes.AsObject
    >(this.serviceClient?.searchAirlines, req, {});
  };

  exportBookingFlight = ({
    sortsList,
    filter,
  }: ExportBookingFlightReq.AsObject): Promise<ExportBookingFlightRes.AsObject> => {
    const req = new ExportBookingFlightReq();
    const filterObj = new ExportBookingFlightReq.Filter();

    if (filter) {
      filter.toDate && filterObj.setToDate(filter.toDate);
      filter.fromDate && filterObj.setFromDate(filter.fromDate);
      filter.passengerName &&
        filterObj.setPassengerName(filter.passengerName?.trim() || '');
      filter.contactPhone &&
        filterObj.setContactPhone(filter.contactPhone?.trim() || '');
      filter.bookingCode &&
        filterObj.setBookingCode(filter.bookingCode?.trim() || '');
      filter.statusesList && filterObj.setStatusesList(filter.statusesList);
    }

    req.setFilter(filterObj);
    req.setSortsList(
      sortsList.map((s) => {
        const sortItemObj = new SortItem();
        sortItemObj.setDesc(s.desc);
        sortItemObj.setType(s.type);
        return sortItemObj;
      })
    );

    return this.grpc<
      ExportBookingFlightReq,
      ExportBookingFlightRes,
      ExportBookingFlightRes.AsObject
    >(this.serviceClient?.exportBookingFlight, req, {});
  };

  getSeatMap = ({
    flightId,
    itineraryId,
    key,
    segmentIndex,
    isDomestic,
  }: GetSeatMapReq.AsObject): Promise<GetSeatMapRes.AsObject> => {
    const req = new GetSeatMapReq();
    req.setFlightId(flightId);
    req.setItineraryId(itineraryId);
    req.setKey(key);
    req.setSegmentIndex(segmentIndex);
    req.setIsDomestic(isDomestic);
    return this.grpc<GetSeatMapReq, GetSeatMapRes, GetSeatMapRes.AsObject>(
      this.serviceClient?.getSeatMap,
      req,
      {}
    );
  };

  calcMakeupPrice = ({
    makeupPrice,
  }: CalcMakeupPriceReq.AsObject): Promise<CalcMakeupPriceRes.AsObject> => {
    const req = new CalcMakeupPriceReq();
    req.setMakeupPrice(makeupPrice);
    return this.grpc<
      CalcMakeupPriceReq,
      CalcMakeupPriceRes,
      CalcMakeupPriceRes.AsObject
    >(this.serviceClient.calcMakeupPrice, req, {});
  };

  getBaggageOptions = ({
    flightId,
    itineraryId,
    key,
    isDomestic,
  }: GetBaggageOptionsReq.AsObject): Promise<GetBaggageOptionsRes.AsObject> => {
    const req = new GetBaggageOptionsReq();
    req.setFlightId(flightId);
    req.setItineraryId(itineraryId);
    req.setKey(key);
    req.setIsDomestic(isDomestic);
    return this.grpc<
      GetBaggageOptionsReq,
      GetBaggageOptionsRes,
      GetBaggageOptionsRes.AsObject
    >(this.serviceClient.getBaggageOptions, req, {});
  };

  createTransferBookingRequest = ({
    bookingCode,
  }: CreateTransferBookingRequestReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new CreateTransferBookingRequestReq();
    req.setBookingCode(bookingCode);
    return this.grpc<
      CreateTransferBookingRequestReq,
      BasicRes,
      BasicRes.AsObject
    >(this.serviceClient.createTransferBookingRequest, req, {});
  };

  listTransferBookingRequest = ({
    filter,
    pagination,
  }: ListTransferBookingRequestReq.AsObject): Promise<ListTransferBookingRequestRes.AsObject> => {
    const req = new ListTransferBookingRequestReq();
    const filterObj = new ListTransferBookingRequestReq.Filter();

    if (filter) {
      filter.agentCode && filterObj.setAgentCode(filter.agentCode);
      filter.requestId && filterObj.setRequestId(filter.requestId);
      filter.status && filterObj.setStatus(filter.status);
    }

    req.setFilter(filterObj);
    const paginationObj = new PaginationReq();

    if (pagination) {
      paginationObj.setPageNumber(pagination.pageNumber);
      paginationObj.setPageLimit(pagination.pageLimit);
    }
    req.setPagination(paginationObj);

    return this.grpc<
      ListTransferBookingRequestReq,
      ListTransferBookingRequestRes,
      ListTransferBookingRequestRes.AsObject
    >(this.serviceClient.listTransferBookingRequest, req, {});
  };

  getTransferBookingRequestDetail = ({
    requestId,
    bookingCode,
  }: GetTransferBookingRequestDetailReq.AsObject): Promise<GetTransferBookingRequestDetailRes.AsObject> => {
    const req = new GetTransferBookingRequestDetailReq();

    req.setRequestId(requestId || '');
    req.setBookingCode(bookingCode || '');

    return this.grpc<
      GetTransferBookingRequestDetailReq,
      GetTransferBookingRequestDetailRes,
      GetTransferBookingRequestDetailRes.AsObject
    >(this.serviceClient.getTransferBookingRequestDetail, req, {});
  };

  handleTransferRequestAction = ({
    requestId,
    action,
  }: HandleTransferRequestActionReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new HandleTransferRequestActionReq();

    req.setRequestId(requestId);
    req.setAction(action);

    return this.grpc<
      HandleTransferRequestActionReq,
      BasicRes,
      BasicRes.AsObject
    >(this.serviceClient.handleTransferRequestAction, req, {});
  };

  cancelTransferBookingRequest = ({
    bookingCode,
  }: CancelTransferBookingRequestReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new CancelTransferBookingRequestReq();

    req.setBookingCode(bookingCode);
    return this.grpc<
      CancelTransferBookingRequestReq,
      BasicRes,
      BasicRes.AsObject
    >(this.serviceClient.cancelTransferBookingRequest, req, {});
  };

  bizziBookingFlight = ({
    currency,
    flightId,
    isPublic,
    key,
    note,
    sessionId,
    passengersList,
    contactInfo,
    confirmPrice,
    invoicingInformation,
    timezone,
    makeupPrice,
    domesticFlightPairsList,
    isDraftForOverride,
    inPolicy,
    reasonOutPolicy,
    bizziCreatedBy,
  }: BookingFlightReq.AsObject): Promise<BizziBookingFlightRes.AsObject> => {
    const req = new BookingFlightReq();

    req.setReasonOutPolicy(reasonOutPolicy ?? '');
    req.setCurrency(currency);
    req.setFlightId(flightId);
    req.setIsPublic(isPublic);
    req.setKey(key);
    req.setNote(note);
    req.setSessionId(sessionId);
    req.setConfirmPrice(confirmPrice);
    req.setTimezone(timezone);
    req.setInPolicy(inPolicy ?? false);
    req.setMakeupPrice(makeupPrice);
    req.setDomesticFlightPairsList(
      domesticFlightPairsList.map((domesticFlight) => {
        const domesticFlightObj = new BookingFlightReq.FlightPair();

        domesticFlightObj.setFlightId(domesticFlight.flightId);
        domesticFlightObj.setKey(domesticFlight.key);

        return domesticFlightObj;
      })
    );

    if (bizziCreatedBy) {
      req.setBizziCreatedBy(bizziCreatedBy);
    }

    if (invoicingInformation) {
      const invoicingInfo = new InvoicingInformation();
      if (invoicingInformation.companyInfo) {
        const invoicingCompany = new InvoicingInformation.Company();
        invoicingCompany.setAddress(invoicingInformation.companyInfo?.address);
        invoicingCompany.setName(invoicingInformation.companyInfo?.name);
        invoicingCompany.setTaxCode(invoicingInformation.companyInfo?.taxCode);
        invoicingInfo.setCompanyInfo(invoicingCompany);
      }
      if (invoicingInformation.receiverInfo) {
        const invoicingReceiver = new InvoicingInformation.Receiver();
        invoicingReceiver.setAddress(
          invoicingInformation.receiverInfo?.address
        );
        invoicingReceiver.setName(invoicingInformation.receiverInfo?.name);
        invoicingReceiver.setEmail(invoicingInformation.receiverInfo?.email);
        invoicingReceiver.setNote(invoicingInformation.receiverInfo?.note);
        invoicingReceiver.setPhone(invoicingInformation.receiverInfo?.phone);
        invoicingInfo.setReceiverInfo(invoicingReceiver);
      }

      req.setInvoicingInformation(invoicingInfo);
    }
    const passengersListReq = passengersList.map((passenger) => {
      const passengerReq = new BookingFlightReq.Passenger();
      const {
        dob,
        firstName,
        gender,
        lastName,
        type,
        expirationDate,
        nationality,
        passportNumber,
        idIssueCountry,
        seatOptionsList,
        baggageOptionsList,

        expenseRequestId,
        phoneCode,
        phoneNumber,
      } = passenger;

      seatOptionsList.forEach((seatOption) => {
        const seatOptionReq = new SeatSelection();
        seatOptionReq.setItineraryId(seatOption.itineraryId);
        seatOptionReq.setSegmentIndex(seatOption.segmentIndex);
        seatOptionReq.setRowNumber(seatOption.rowNumber);
        seatOptionReq.setSeatCode(seatOption.seatCode);

        passengerReq.addSeatOptions(seatOptionReq);
      });
      baggageOptionsList.forEach((b) => {
        const baggageObj = new BaggageSelection();
        baggageObj.setItineraryId(b.itineraryId);
        baggageObj.setOptionId(b.optionId);

        passengerReq.addBaggageOptions(baggageObj);
      });

      passengerReq.setDob(convertLocalToUTCDate(dob ?? 0).getTime());
      passengerReq.setFirstName(firstName);
      passengerReq.setLastName(lastName);
      passengerReq.setType(type);
      passengerReq.setGender(gender);

      expirationDate && passengerReq.setExpirationDate(expirationDate);
      nationality && passengerReq.setNationality(nationality);
      passportNumber && passengerReq.setPassportNumber(passportNumber);
      idIssueCountry && passengerReq.setIdIssueCountry(idIssueCountry);

      expenseRequestId && passengerReq.setExpenseRequestId(expenseRequestId);
      phoneCode && passengerReq.setPhoneCode(phoneCode);
      phoneNumber && passengerReq.setPhoneNumber(phoneNumber);
      return passengerReq;
    });
    req.setPassengersList(passengersListReq);

    const contactInfoReq = new BookingFlightReq.ContactInfo();
    if (contactInfo) {
      contactInfoReq.setTitle(contactInfo.title);
      contactInfoReq.setFirstName(contactInfo.firstName);
      contactInfoReq.setLastName(contactInfo.lastName);

      contactInfoReq.setPhoneCode(contactInfo.phoneCode);
      contactInfoReq.setPhoneNumber(contactInfo.phoneNumber);
      contactInfoReq.setEmail(contactInfo.email);
      contactInfoReq.setAddress(contactInfo.address);
      contactInfoReq.setNote(contactInfo.note);
    }
    req.setContactInfo(contactInfoReq);
    req.setIsDraftForOverride(isDraftForOverride);
    // if (isPublic) {
    //   console.log('BOOKING', req.toObject());
    //   console.groupEnd();
    // } else {
    //   console.group(`START Booking: ${key}`);

    //   console.log('VALIDATE BOOKING', req.toObject());
    // }

    return this.grpc<
      BookingFlightReq,
      BizziBookingFlightRes,
      BizziBookingFlightRes.AsObject
    >(this.serviceClient.bizziBookingFlight, req, {});
  };

  bizziIssueTicket = ({
    poCode,
  }: BizziIssueTicketReq.AsObject): Promise<BizziIssueTicketRes.AsObject> => {
    const req = new BizziIssueTicketReq();
    req.setPoCode(poCode);
    return this.grpc<
      BizziIssueTicketReq,
      BizziIssueTicketRes,
      BizziIssueTicketRes.AsObject
    >(this.serviceClient.bizziIssueTicket, req, {});
  };

  bizziRetrieveBookingFlight = ({
    poCode,
  }: BizziRetrieveBookingFlightReq.AsObject): Promise<RetrieveBookingFlightRes.AsObject> => {
    const req = new BizziRetrieveBookingFlightReq();

    req.setPoCode(poCode);

    return this.grpc<
      BizziRetrieveBookingFlightReq,
      RetrieveBookingFlightRes,
      RetrieveBookingFlightRes.AsObject
    >(this.serviceClient.bizziRetrieveBookingFlight, req, {});
  };
}

export const flightApiService = new FlightApi();

export default flightApiService;
